{
  "day": "Day",
  "map_text": "Open",
  "alert": {
    "text": "Now! Tournament hotel reservations. ",
    "link": "here"
  },
  "download":{
    "show": "Show"
  },
  "itf": {
    "404": {
      "text": "We couldn’t find the page you’re looking for ..."
    },
    "nav": {
      "news": "News",
      "articles": "News",
      "photos": "Photos",
      "videos": "Videos",
      "latest_news": "Latest News",
      "tournament": "Tournament",
      "draws": "Draws (Live)",
      "order_of_play": "Order of Play (Live)",
      "infos": "Infos",
      "partners": "Partners",
      "downloads": "Downloads",
      "order_of_play_button": "Order of Play (Live)",
      "venue_heading": "Venue",
      "venue": "Venue",
      "venue_href": "/venue",
      "parking": "Parking",
      "parking_href": "/parking",
      "hospitality": "Hospitality",
      "pre_reservation": "Hotel Reservation",
      "champions": "Champions",
      "champions_singles": "Champions Singles",
      "champions_doubles": "Champions Doubles"
    },
    "partner": {
      "partner": "Partners",
      "all_partner": "All Partners",
      "categories": {
        "NAMESPONSOR": "NAMESPONSOR",
        "PREMIUM PARTNER": "PREMIUM PARTNER",
        "PARTNER": "PARTNER",
        "MEDIEN PARTNER": "MEDIEN PARTNER"
      }
    },
    "footer": {
      "imprint": "Imprint",
      "imprint_link": "/en/imprint",
      "privacy_policy": "Privacy Policy",
      "privacy_policy_link": "/en/privacy-policy",
      "contact": "Contact",
      "contact_link": "/en/contact"
    }
  },
  "auth": {
    "agree": "I agree to",
    "createAccount": "Create account",
    "createNewAccount": "Create New Account",
    "email": "Email",
    "login": "Login",
    "password": "Password",
    "recover_password": "Recover password",
    "sign_up": "Sign Up",
    "keep_logged_in": "Keep me logged in",
    "termsOfUse": "Terms of Use.",
    "reset_password": "Reset password"
  },
  "404": {
    "title": "This page’s gone fishing.",
    "text": "If you feel that it’s not right, please send us a message at ",
    "back_button": "Back to dashboard"
  },
  "rating": {
    "singleIcon": "Single Icon",
    "twoIcons": "Two Icons",
    "large": "Large",
    "numbers": "Numbers",
    "halves": "Halves",
    "small": "Small"
  },
  "typography": {
    "primary": "Primary text styles",
    "secondary": "Secondary text styles"
  },
  "colorPickers": {
    "simple": "Simple",
    "slider": "Slider",
    "advanced": "Advanced"
  },
  "highlightcarousel": {
    "button": "READ MORE"
  },
  "footer": {
    "languages": "Languages",
    "social_media": "Social Media"
  },
  "other_news": "Other News",
  "all_news": "SEE ALL NEWS",
  "pages": {
    "news": {
      "title": "News",
      "type": {
        "news": "News",
        "videos": "Videos",
        "photos": "Photos"
      },
      "more": "More..."
    }
  },
  "accreditation": {
    "title": "Accreditation",
    "errors": {
      "title": "Errors",
      "role": "Role",
      "missing": "missing",
      "firstname": "Firstname",
      "lastname": "Lastname",
      "country": "Country",
      "status": "Status",
      "playing_draw": "Draw",
      "date": "Duration Of Stay",
      "no_role": "No role selected",
      "street": "Street",
      "zipcode": "Zipcode",
      "city": "City",
      "resident": "Resident",
      "phone": "Phone",
      "date_of_birth": "Date of birth",
      "ipin": "IPIN",
      "email": "Email"
    },
    "steps": {
      "titles": {
        "select_role": "Select &#x200b;Role",
        "personal_information": "Personal &#x200b;Information",
        "check": "Check"
      },
      "content": {
        "select_role_type": "Select Role",
        "player": "PLAYER",
        "coach": "COACH",
        "next": "Next",
        "back": "Back",
        "finish": "Finish",
        "select_roles": {
          "title": {
            "player": "PLAYER",
            "coach": "COACH"
          },
          "information": "Information",
          "resident": "Resident",
          "firstname": "Firstname",
          "lastname": "Lastname",
          "country": "Country",
          "status": "Status",
          "playing_draw": "Draw",
          "merge_me": "Merge me with another  player in a twin bedroom.",
          "duration_of_stay": "Duration Of Stay",
          "days": "Days",
          "reduced_hospitality": {
            "one": "Reduced Hospitality",
            "two": "Bed and Breakfast from the night before QF of singles and SF of doubles until elimination."
          },
          "merging": {
            "one": "Merging",
            "two": "Players with 'Reduced Hospitality' will be automatically merged with other players of the same sex. Requests can be made, but there is no promise that they will be considered."
          }
        },
        "personal_information" : {
          "title": "PERSONAL INFORMATION",
          "firstname": "Firstname",
          "lastname": "Lastname",
          "email": "Email",
          "street": "Street",
          "zipcode": "Zipcode",
          "city": "City",
          "country": "Country",
          "phone": "Phone",
          "date_of_birth": "Date of birth",
          "ipin": "IPIN"
        },
        "summary": {
          "title": "SUMMARY",
          "personal_information": "Personal Information",
          "roles": "Roles",
          "role": {
            "title": "Role",
            "player": "PLAYER",
            "coach": "COACH"
          },
          "resident": "Resident"
        },
        "confirmation": {
          "title": "CONFIRMATION",
          "line_one": "Thank you for your accreditation.",
          "line_two": "You will receive a confirmation by email as soon as possible.",
          "line_three": "We are looking forword to see you at the tournament.",
          "line_three_add": ""
        }
      }
    }
  },
  "hospitality": {
    "msg": "Thanks for the reservations, you can come back next year!",
    "errors": {
      "title": "Errors",
      "room": "Room",
      "missing": "missing",
      "firstname": "Firstname",
      "lastname": "Lastname",
      "country": "Country",
      "status": "Status",
      "playing_draw": "Draw",
      "date": "Duration Of Stay",
      "no_room": "No room selected",
      "street": "Street",
      "zipcode": "Zipcode",
      "city": "City",
      "resident": "Resident",
      "ipin": "IPIN"
    },
    "steps": {
      "titles": {
        "select_rooms": "Select &#x200b;Rooms",
        "personal_information": "Personal &#x200b;Information",
        "check": "Check"
      },
      "content": {
        "select_room_type": "Select Room Type",
        "single_bedroom": "Single Bedroom",
        "double_bedroom": "Twin Bedroom",
        "next": "Next",
        "back": "Back",
        "finish": "Finish",
        "select_rooms": {
          "title": {
            "single": "SINGLE BEDROOM",
            "double": "TWIN BEDROOM"
          },
          "information": "Information",
          "resident": "Resident",
          "firstname": "Firstname",
          "lastname": "Lastname",
          "country": "Country",
          "status": "Status",
          "playing_draw": "Draw",
          "ipin": "IPIN",
          "merge_me": "Merge me with another  player in a twin bedroom.",
          "duration_of_stay": "Duration Of Stay",
          "days": "Days",
          "reduced_hospitality": {
            "one": "Reduced Hospitality",
            "two": "Bed and Breakfast from the night before QF of singles and SF of doubles until elimination."
          },
          "merging": {
            "one": "Merging",
            "two": "Players with 'Reduced Hospitality' will be automatically merged with other players of the same sex. Requests can be made, but there is no promise that they will be considered."
          }
        },
        "personal_information" : {
          "title": "PERSONAL INFORMATION",
          "firstname": "Firstname",
          "lastname": "Lastname",
          "email": "Email",
          "street": "Street",
          "zipcode": "Zipcode",
          "city": "City",
          "country": "Country"
        },
        "summary": {
          "title": "SUMMARY",
          "personal_information": "Personal Information",
          "rooms": "Rooms",
          "room": {
            "title": "Room",
            "single": "SINGLE BEDROOM",
            "double": "TWIN BEDROOM"
          },
          "resident": "Resident",
          "from": "From",
          "till": "Till"
        },
        "confirmation": {
          "title": "CONFIRMATION",
          "line_one": "Thank you for your reservation.",
          "line_two": "You will receive a confirmation by email as soon as possible, this can take up to 24 hours.",
          "line_three": "If you have any further questions or have not received a confirmation, please feel free to contact ",
          "line_three_add": "."
        }
      }
    }
  },
  "buttons": {
    "advanced": "Buttons With Icons",
    "size": "Button Sizes",
    "tags": "Button Tags",
    "button": "Button",
    "buttonGroups": "Button Groups",
    "buttonsDropdown": "Buttons with dropdown",
    "split": "Split",
    "splitTo": "Split to",
    "customIcon": "Custom icon",
    "content": "Content",
    "buttonToggles": "Button Toggles",
    "pagination": "Pagination",
    "a-link": "Open EpicSpinners",
    "router-link": "Move to Charts",
    "colors": "Button Colors",
    "disabled": "Disabled",
    "dropdown": "DROPDOWN",
    "hover": "HOVER",
    "types": "Button Types",
    "pressed": "PRESSED",
    "default": "Default",
    "outline": "Outline",
    "flat": "Flat",
    "large": "Large",
    "small": "Small",
    "normal": "Normal",
    "success": "Success",
    "info": "Info",
    "danger": "Danger",
    "warning": "Warning",
    "gray": "Gray",
    "dark": "Dark"
  },
  "charts": {
    "horizontalBarChart": "Horizontal Bar Chart",
    "verticalBarChart": "Vertical Bar Chart",
    "lineChart": "Line Chart",
    "pieChart": "Pie Chart",
    "donutChart": "Donut Chart",
    "bubbleChart": "Bubble Chart"
  },
  "collapse": {
    "basic": "Basic Collapse",
    "collapseWithBackground": "Collapse with background",
    "collapseWithCustomHeader": "Collapse with custom header",
    "firstHeader": "Expand This Block",
    "secondHeader": "Another Block",
    "content": {
      "title": "Great Things",
      "text": "There is something about parenthood that gives us a sense of history and a deeply rooted desire to send on into the next generation the great things we have discovered about life."
    }
  },
  "sliders": {
    "slider": "Sliders",
    "range": "Ranges",
    "simple": "simple",
    "value": "Value",
    "label": "Label",
    "labelPlusIcon": "label + icon",
    "pins": "Pins",
    "pinsAndValue": "pins & value"
  },
  "popovers": {
    "popover": "Popover",
    "popoverStyle": "Popover Style",
    "popoverPlacement": "Popover Placement",
    "minimalTooltip": "Minimal Tooltip",
    "anotherOneTooltip": "Another One Tooltip"
  },
  "datepickers": {
    "dateOfBirth": "Date of birth",
    "daysOfTheWeek": "Days of the week",
    "setupMeeting": "Setup meeting",
    "upcomingVacation": "Upcoming vacation",
    "multipleAndDisabledDates": "Multiple & disabled dates",
    "inline": "Inline"
  },
  "dashboard": {
    "versions": "Versions",
    "setupRemoteConnections": "Setup Remote Connections",
    "currentVisitors": "Current Visitors",
    "charts": {
      "trendyTrends": "Trendy Trends",
      "showInMoreDetail": "Show in more detail",
      "loadingSpeed": "Loading speed",
      "topContributors": "Top contributors",
      "showNextFive": "Show next five",
      "commits": "Commits"
    },
    "info": {
      "componentRichTheme": "component-rich theme",
      "completedPullRequests": "completed pull requests",
      "users": "users",
      "points": "points",
      "units": "units",
      "exploreGallery": "Explore gallery",
      "viewLibrary": "View Library",
      "commits": "commits",
      "components": "components",
      "teamMembers": "team members"
    },
    "table": {
      "title": "Awesome table",
      "brief": "Brief",
      "detailed": "Detailed",
      "resolve": "Resolve",
      "resolved": "Resolved"
    },
    "tabs": {
      "overview": {
        "title": "Overview",
        "built": "Built with Vue.js framework",
        "free": "Absolutely free for everyone",
        "fresh": "Fresh and crisp design",
        "mobile": "Responsive and optimized for mobile",
        "components": "Tons of useful components",
        "nojQuery": "Completely jQuery free"
      },
      "billingAddress": {
        "title": "Billing Address",
        "personalInfo": "Personal Info",
        "firstName": "First name & Last Name",
        "email": "Email",
        "address": "Address",
        "companyInfo": "Company Info",
        "city": "City",
        "country": "Country",
        "infiniteConnections": "Infinite connections",
        "addConnection": "Add Connection"
      },
      "bankDetails": {
        "title": "Bank Details",
        "detailsFields": "Details Fields",
        "bankName": "Bank Name",
        "accountName": "Account Name",
        "sortCode": "Sort Code",
        "accountNumber": "Account Number",
        "notes": "Notes",
        "sendDetails": "Send Details"
      }
    },
    "navigationLayout": "navigation layout",
    "topBarButton": "Top Bar",
    "sideBarButton": "Side Bar"
  },
  "notificationsPage": {
    "notifications": {
      "title": "Notifications",
      "gray": "Processing",
      "dark": "New Label",
      "success": "Paid",
      "successMessage": "You successfully read this important alert message.",
      "info": "Info",
      "infoMessage": "This alert needs your attention, but it's not super important.",
      "warning": "On Hold",
      "warningMessage": "Better check yourself, you're not looking too good.",
      "danger": "Danger",
      "dangerMessage": "Change a few things up and try submitting again."
    },
    "popovers": {
      "title": "Tooltips & Popovers",
      "popoverTitleLabel": "Popover Title",
      "popoverTextLabel": "Popover Text",
      "popoverIconLabel": "Popover Icon (fontawesome)",
      "showPopover": "Show Popover",
      "topTooltip": "top",
      "rightTooltip": "rightside",
      "leftTooltip": "left",
      "bottomTooltip": "below"
    },
    "toasts": {
      "title": "Toasts",
      "textLabel": "Text",
      "durationLabel": "Duration (milliseconds)",
      "iconLabel": "Icon (fontawesome)",
      "fullWidthLabel": "Fullwidth",
      "launchToast": "Launch toast"
    }
  },
  "forms": {
    "controls": {
      "title": "Checkboxes, Radios, Switches, Toggles",
      "radioDisabled": "Disabled Radio",
      "radio": "Radio",
      "subscribe": "Subscribe to newsletter",
      "unselected": "Unselected checkbox",
      "selected": "Selected checkbox",
      "readonly": "Readonly checkbox",
      "disabled": "Disabled checkbox",
      "error": "Checkbox with error",
      "errorMessage": "Checkbox with error messages"
    },
    "dateTimePicker": {
      "title": "Date time pickers",
      "basic": "Basic",
      "time": "Time",
      "range": "Range",
      "multiple": "Multiple",
      "disabled": "Disabled",
      "customFirstDay": "Custom first day",
      "customDateFormat": "Custom date format"
    },
    "inputs": {
      "emailValidatedSuccess": "Email (validated with success)",
      "emailValidated": "Email (validated)",
      "inputWithIcon": "Input With Icon",
      "inputWithButton": "Input With Button",
      "inputWithClearButton": "Input With Clear Button",
      "inputWithRoundButton": "Input With Round Button",
      "textInput": "Text Input",
      "textInputWithDescription": "Text Input (with description)",
      "textArea": "Text Area",
      "title": "Inputs",
      "upload": "UPLOAD"
    },
    "mediumEditor": {
      "title": "Medium Editor"
    },
    "selects": {
      "country": "Country Select",
      "countryMulti": "Country Multi Select",
      "multi": "Multi Select",
      "simple": "Simple Select",
      "searchable": "Select with search",
      "searchableMulti": "Multi Select with search",
      "title": "Selects"
    }
  },
  "grid": {
    "desktop": "Desktop Grid",
    "fixed": "Fixed Grid",
    "offsets": "Offsets",
    "responsive": "Responsive Grid"
  },
  "icons": {
    "back": "Back to all icons",
    "none": "No icons found",
    "search": "Icon search",
    "title": "Icons"
  },
  "spinners": {
    "title": "Spinners",
    "poweredBy": "Powered by"
  },
  "language": {
    "brazilian_portuguese": "Português",
    "english": "English",
    "spanish": "Spanish",
    "simplified_chinese": "Simplified Chinese",
    "persian": "Persian"
  },
  "menu": {
    "auth": "Auth",
    "rating": "Rating",
    "buttons": "Buttons",
    "charts": "Charts",
    "colorPickers": "Color Pickers",
    "collapses": "Collapses",
    "timelines": "Timelines",
    "dashboard": "Dashboard",
    "events": "Events",
    "qrscan": "QrScanner",
    "overview": "Overview",
    "general": "General",
    "general_overview": "Overview",
    "media": "Media",
    "media_general": "General",
    "members": "Members",
    "champions": "Champions",
    "settings": "Settings",
    "amvs": "AMVS",
    "amvs_overview": "Overview",
    "pra": "PRA",
    "pra_registration": "Registration",
    "pra_import": "Import",
    "pra_foto": "Foto",
    "pra_pdf": "PDF",
    "pra_export_picture" : "Export Picture",
    "pra_export_pass" : "Export Pass",
    "pra_export_label" : "Export Label",
    "analytics": "Analytics",
    "analytics_general": "General",
    "analytics_map": "Map",
    "services": "Services",
    "requests": "Requests",
    "livescore": "Livescore / OoP",
    "draws": "Draws",
    "draws_editor": "Draw Editor",
    "oops": "Order of Plays",
    "oops_editor": "Order of Play Editor",
    "ls_overview": "Overview",
    "brackets": "Brackets",
    "request_export": "Export",
    "participants": "Participants",
    "player": "Player",
    "print": "Print",
    "formElements": "Form Elements",
    "forms": "Forms",
    "mediumEditor": "Medium Editor",
    "grid": "Grid",
    "icons": "Icons",
    "cards": "Cards",
    "spinners": "Spinners",
    "login": "Login",
    "maps": "Maps",
    "pages": "Pages",
    "modals": "Modals",
    "notifications": "Notifications",
    "progressBars": "Progress Bars",
    "signUp": "Sign up",
    "statistics": "Statistics",
    "lists": "Lists",
    "tables": "Tables",
    "markupTables": "Markup Tables",
    "dataTables": "Data Tables",
    "chips": "Chips",
    "tabs": "Tabs",
    "typography": "Typography",
    "uiElements": "UI Elements",
    "treeView": "Tree view",
    "dateTimePickers": "Date time pickers",
    "fileUpload": "File Upload",
    "colors": "Colors",
    "spacing": "Spacing",
    "sliders": "Sliders",
    "popovers": "Popovers",
    "chat": "Chat",
    "google-maps": "Google Maps",
    "yandex-maps": "Yandex Maps",
    "leaflet-maps": "Leaflet Maps",
    "bubble-maps": "Bubble Maps",
    "line-maps": "Line Maps",
    "login-singup": "Login/Signup",
    "404-pages": "404 Pages",
    "faq": "Faq"
  },
  "messages": {
    "all": "See all messages",
    "new": "New messages from {name}",
    "mark_as_read": "Mark As Read"
  },
  "modal": {
    "cancel": "Cancel",
    "close": "Close",
    "confirm": "Confirm",
    "large": "Large",
    "largeTitle": "Large Modal",
    "medium": "Medium",
    "mediumTitle": "Medium Modal",
    "small": "Small",
    "smallTitle": "Small Modal",
    "static": "Static",
    "staticMessage": "This is a static modal, backdrop or escape click will not close it.",
    "staticTitle": "Static Modal",
    "title": "Modals",
    "titlePosition": "Modal Position",
    "top": "Top",
    "right": "Right",
    "bottom": "Bottom",
    "left": "Left",
    "message": "There are three species of zebras: the plains zebra, the mountain zebra and the Grévy's zebra. The plains zebra and the mountain zebra belong to the subgenus Hippotigris, but Grévy's\n        zebra is the sole species of subgenus\n        Dolichohippus. The latter resembles an ass, to which it is closely\n        related, while the former two are more\n        horse-like. All three belong to the genus Equus, along with other living\n        equids."
  },
  "dropdown": {
    "default": "Default",
    "withArrow": "With arrow",
    "note": "Note",
    "noteText": "Dropdown will open in the specified direction if there is enough space on the screen, otherwise the direction will automatically change",
    "top": "TOP",
    "right": "RIGHT",
    "bottom": "BOTTOM",
    "left": "LEFT"
  },
  "fileUpload": {
    "advancedMediaGallery": "Advanced, Media Gallery",
    "advancedUploadList": "Advanced, Upload List",
    "mediaGallery": "Media Gallery",
    "uploadList": "Upload List",
    "single": "Single",
    "dragNdropFiles": "Drag’n’drop files or",
    "uploadedOn": "Uploaded on",
    "fileDeleted": "File was successfully deleted",
    "undo": "Undo",
    "preview": "Preview",
    "delete": "Delete",
    "deleteFile": "Delete file",
    "uploadFile": "Upload file",
    "uploadMedia": "Upload media",
    "addAttachment": "Add attachment",
    "modalTitle": "File validation",
    "modalText": "File type is incorrect!"
  },
  "chips": {
    "chips": {
    "title": "Chips",
    "primary": "Primary chip",
    "secondary": "Secondary chip",
    "success": "Success chip",
    "info": "Info chip",
    "danger": "Danger chip",
    "warning": "Warning chip",
    "gray": "Gray chip",
    "dark": "Dark chip"
    },
    "badges": {
    "title": "Badges",
    "primary": "Primary badge",
    "secondary": "Secondary badge",
    "success": "Success badge",
    "info": "Info badge",
    "danger": "Danger badge",
    "warning": "Warning badge",
    "gray": "Gray badge",
    "dark": "Dark badge"
    }
  },
  "navbar": {
    "messageUs": "Web development inquiries:",
    "repository": "GitHub Repo"
  },
  "notifications": {
    "all": "See all notifications",
    "mark_as_read": "Mark as read",
    "sentMessage": "sent you a message",
    "uploadedZip": "uploaded a new Zip file with {type}",
    "startedTopic": "started a new topic"
  },
  "timelines": {
    "horizontalSimple": "Horizontal Simple",
    "horizontalCards": "Horizontal Cards",
    "verticalSimple": "Vertical Simple",
    "verticalLabel": "Vertical With Label",
    "verticalCentered": "Vertical Centered",
    "horizontalActionFirst": "Complete drafts",
    "horizontalActionSecond": "Push site live",
    "horizontalActionThird": "Start ICO",
    "titleFirst": "Make design",
    "titleSecond": "Develop an app",
    "titleThird": "Submit an app",
    "titleDateFirst": "",
    "titleDateSecond": "May 22 10:00",
    "titleDateThird": "July 19 17:45",
    "firstDate": "February 2018",
    "secondDate": "March 2018",
    "thirdDate": "April 2018",
    "contentFirst": "The unique stripes of zebras make them one of the animals most familiar to people. They occur in a variety of habitats, such as grasslands, savannas, woodlands, thorny scrublands.",
    "contentSecond": "They occur in a variety of habitats, such as grasslands, savannas, woodlands, thorny scrublands.",
    "contentThird": "However, various anthropogenic factors have had a severe impact on zebra populations"
  },
  "progressBars": {
    "circle": "Circle",
    "horizontal": "Horizontal",
    "colors": "Colors"
  },
  "lists": {
    "customers": "Customers",
    "recentMessages": "Recent Messages",
    "archieved": "Archieved",
    "starterKit": "Starter Kit",
    "notifications": "Notifications",
    "routerSupport": "Router Support"
  },
  "tables": {
    "basic": "Basic Table",
    "stripedHoverable": "Striped, Hoverable",
    "labelsActions": "Labels, Actions as Buttons",
    "sortingPaginationActionsAsIcons": "Sorting, Pagination, Actions as Icons",
    "star": "Star",
    "unstar": "Unstar",
    "edit": "Edit",
    "delete": "Delete",
    "searchByName": "Search by name",
    "searchTrendsBadges": "Search, Trends, Badges",
    "perPage": "Per Page",
    "report": "Report",
    "infiniteScroll": "Infinite Scroll",
    "selectable": "Selectable",
    "selected": "Selected",
    "serverSidePagination": "Server-Side Pagination",
    "emptyTable": "Empty Table",
    "noDataAvailable": "No Data Available.",
    "noReport": "There is no data to display. Report will be available on November 3, 2018.",
    "loading": "Loading",
    "headings": {
      "email": "Email",
      "name": "Name",
      "firstName": "First Name",
      "lastName": "Last Name",
      "status": "Status",
      "country": "Country",
      "location": "Location"
    }
  },
  "user": {
    "language": "Change Language",
    "logout": "Logout",
    "profile": "My Profile"
  },
  "treeView": {
    "basic": "Basic",
    "icons": "Icons",
    "selectable": "Selectable",
    "editable": "Editable",
    "advanced": "Advanced"
  },
  "chat": {
    "title": "Chat",
    "sendButton": "Send"
  },
  "spacingPlayground": {
    "value": "Value",
    "margin": "Margin",
    "padding": "Padding"
  },
  "spacing": {
    "title": "Spacing"
  },
  "cards": {
    "cards": "Cards",
    "fixed": "Fixed",
    "floating": "Floating",
    "contentText": "The unique stripes of zebras make them one of the animals most familiar to people.",
    "contentTextLong": "The unique stripes of zebras make them one of the animals most familiar to people. They occur in a variety of habitats, such as grasslands, savannas, woodlands, thorny scrublands, mountains, and coastal hills. Various anthropogenic factors have had a severe impact on zebra populations, in particular hunting for skins and habitat destruction. Grévy's zebra and the mountain zebra are endangered. While plains zebras are much more plentiful, one subspecies, the quagga.",
    "rowHeight": "Row height",
    "title": {
      "default": "Default",
      "withControls": "With controls",
      "customHeader": "Custom header",
      "withoutHeader": "Without header",
      "withImage": "With Image",
      "withTitleOnImage": "With title on image",
      "withCustomTitleOnImage": "With custom title on image",
      "withStripe": "With stripe",
      "withBackground": "With background"
    },
    "button": {
      "main": "Main",
      "cancel": "Cancel",
      "showMore": "Show More",
      "readMore": "Show More"
    },
    "link": {
      "edit": "Edit",
      "setAsDefault": "Set as default",
      "delete": "Delete",
      "traveling": "Traveling",
      "france": "France",
      "review": "Review",
      "feedback": "Leave feedback",
      "readFull": "Read full article",
      "secondaryAction": "Secondary action",
      "action1": "Action 1",
      "action2": "Action 2"
    }
  },
  "colors": {
    "themeColors": "Theme Colors",
    "extraColors": "Extra Colors",
    "gradients": {
      "basic": {
        "title": "Button Gradients"
      },
      "hovered": {
        "title": "Hovered Button Gradients",
        "text": "Lighten 15% applied to an original style (gradient or flat color) for hover state."
      },
      "pressed": {
        "title": "Hovered Button Gradients",
        "text": "Darken 15% applied to an original style (gradient or flat color) for pressed state."
      }
    }
  },
  "tabs": {
    "alignment": "Tabs Alignment",
    "overflow": "Tabs Overflow",
    "hidden": "Tabs with Hidden slider",
    "grow": "Tabs Grow"
  }
}
